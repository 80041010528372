@import url('https://fonts.googleapis.com/css2?family=Jura:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monda:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carlito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

#root {
  background-color: #100f13;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Jura', sans-serif; */
  font-family: 'Raleway', sans-serif;
  /* font-family:  'Golos Text', sans-serif; */
  /* font-family: 'Carlito', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Jura', sans-serif;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input {
  font-size: 16px;
}

@media print {
  body{ 
    background-color: black;
  }
}
